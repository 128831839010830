import {createSlice} from "@reduxjs/toolkit";


const initialLicenseReseller3rdState = {
  listLoading: false,
  licenseReseller3rdEditId: undefined,
  actionsLoading: false,
  totalCount: 0,
  licenseReseller3rdItems: [],
  licenseReseller3rdItem: null,
};

export const callTypes = {
  list: "list",
  action: "action"
};

export const licenseReseller3rdSlice = createSlice({
  name: "licensesReseller3rd",
  initialState: initialLicenseReseller3rdState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.licenseReseller3rdItems = [];
        state.totalCount = 0;
      } else {
        state.actionsLoading = true;
      }
    },
    licenseReseller3rdsFetched: (state, action) => {
      const { totalCount, licenseReseller3rdItems } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.licenseReseller3rdItems = licenseReseller3rdItems;
      state.totalCount = totalCount;
    },
    licenseReseller3rdUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    licenseReseller3rdCreated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    licenseReseller3rdFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.licenseReseller3rdItem = action.payload;
    },
    resetState: (state) => {
      state.listLoading = initialLicenseReseller3rdState.listLoading;
      state.actionsLoading = initialLicenseReseller3rdState.actionsLoading;
      state.totalCount = initialLicenseReseller3rdState.totalCount;
      state.licenseReseller3rdItems = initialLicenseReseller3rdState.licenseReseller3rdItems;
      state.licenseReseller3rdItem = initialLicenseReseller3rdState.licenseReseller3rdItem;
      state.licenseReseller3rdEditId = initialLicenseReseller3rdState.licenseReseller3rdEditId;
    },
    setEditingRes3rdItemId: (state, action) => {
      state.licenseReseller3rdEditId = action.payload;
      state.licenseReseller3rdItem = initialLicenseReseller3rdState.licenseReseller3rdItem;
    }
  }
});
