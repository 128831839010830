import axios from "axios";

export const LICENSES_URL = "/api/license";

function parseFilterParams(queryParams) {
  const {filter: {searchText, activeStatus, resellerId, freeStatus, type}, pageNumber, pageSize, } = queryParams;
  return {
    "page": pageNumber - 1,
    "size": pageSize,
    "searches": {
      "columns": {
        searchBy: searchText,
        type,
        freeStatus,
        activeStatus,
        resellerId
      }
    },
    "sorts": {}
  }
}

export function findLicenses(queryParams) {
  return axios.post(`${LICENSES_URL}/filter`, {...parseFilterParams(queryParams)});
}

export function generateLicenses(formData) {
  return axios.post(`${LICENSES_URL}/generate`, {...formData});
}

export function resetLicense(id) {
  console.log({
    resetId: id
  })
  return axios.get(`${LICENSES_URL}/reset/${id}`);
}

export function activeLicense(id) {
  return axios.get(`${LICENSES_URL}/active/${id}`);
}

export function findAllResellers() {
  return axios.get(`${LICENSES_URL}/resselers`);
}

export function getAll3rdResellers() {
  return axios.get(`${LICENSES_URL}/resselers/3rd`);
}

export function create3rdReseller(formData) {
  return axios.post(`${LICENSES_URL}/resselers/3rd`, {...formData});
}

export function get3rdReseller(id) {
  return axios.get(`${LICENSES_URL}/resselers/3rd/${id}`);
}

export function update3rdReseller(id, formData) {
  return axios.patch(`${LICENSES_URL}/resselers/3rd/${id}`, {...formData});
}

