import axios from "axios";
import {getCookie} from "../../../utils";

export const LOGIN_URL = "/api/user/login";
export const LOGOUT_URL = "/api/user/logout";
export const ME_URL = "/api/admin/info";

export function login(username, password) {
  const formData = new FormData();
  formData.append('username', username);
  formData.append('password', password);
  return axios.post(LOGIN_URL, formData, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
    }
  }).then(() => {
    const csrfToken = getCookie('XSRF-TOKEN');
    return {
      data: {
        accessToken: csrfToken
      }
    }
  });
}

export function logout() {
  return axios.post(LOGOUT_URL, {});
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL).then(() => {
    return {
      data: {username: 'Admin'}
    }
  });
}
