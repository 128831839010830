import axios from "axios";

export const _URL = "/api/company";

export function findBankCards(search) {
  return axios.get(`${_URL}/getAllCompanyLinkedSerial?search=${search}`);
}

export function updateBankAccountNumber({companyId, bankAccountNumber}) {
  return axios.post(`${_URL}/${companyId}/updateBankAccountNumber/${bankAccountNumber}`);
}
