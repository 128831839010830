import {actions} from "../app/modules/Auth";
import {getCookie} from "../app/utils";
import { toast } from 'react-toastify';

export default function setupAxios(axios, store) {
  axios.defaults.headers['X-Requested-With'] = 'VPOSXMLHttpRequest';
  axios.interceptors.request.use(
    config => {
      const csrfToken = getCookie('XSRF-TOKEN');
      if (csrfToken) {
        config.headers['X-XSRF-TOKEN'] = csrfToken;
      }
      return config;
    },
    err => {
      return Promise.reject(err)
    }
  );
  axios.interceptors.response.use(function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response.data;
  }, function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response.status == 401) {
      toast.error("Session Timeout");
      store.dispatch(actions.logout())
    } else {
      toast.error(error.response.statusText);
    }
    return Promise.reject(error);
  });
}
