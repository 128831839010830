// export const LicenseStatusCssClasses = ["danger", "success", "info", ""];
// export const LicenseStatusTitles = ["Suspended", "Active", "Pending", ""];
// export const LicenseTypeCssClasses = ["success", "primary", ""];
// export const LicenseTypeTitles = ["Business", "Individual", ""];

export const defaultSorted = [{ dataField: "id", order: "asc" }];
export const sizePerPageList = [
  { text: "10", value: 10 },
  { text: "20", value: 20 },
  { text: "50", value: 50 },
  { text: "100", value: 100 },
];
export const initialFilter = {
  filter: {
    searchText: "",
    activeStatus: "",
    freeStatus: "",
    type: 1,
    resellerId: null,
  },
  sortOrder: "asc", // asc||desc
  sortField: "id",
  pageNumber: 1,
  pageSize: 10
};
