import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import LicensesPage from "./pages/Licenses";
import BankCardsPage from "./pages/BankCards";

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/licenses" />
        }
        <ContentRoute path="/licenses" component={LicensesPage} />
        <ContentRoute path="/bank-cards" component={BankCardsPage} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
