import * as licenseApi from "../../app/apis/licenseApi";
import * as terminalApi from "../../app/apis/terminalApi";
import {licensesSlice, callTypes} from "./licensesSlice";
import {toast} from "react-toastify";

const {actions} = licensesSlice;

export const fetchLicenses = queryParams => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.list }));
  return licenseApi
    .findLicenses(queryParams)
    .then(response => {
      const { totalElements: totalCount, data: entities  } = response;
      dispatch(actions.licensesFetched({ totalCount, entities }));
    })
    .catch(error => {
      error.clientMessage = "Can't find Licenses";
      dispatch(actions.catchError({ error, callType: callTypes.list }));
    });
};

export const generateLicenses = formData => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .generateLicenses(formData)
    .then(response => {
      dispatch(actions.licenseGenerated(response));
      toast.success("Create License Successfully!");
      return response;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false
    });
};


export const resetLicense = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .resetLicense(id)
    .then(response => {
      dispatch(actions.licenseUpdated());
      toast.success("Reset License Successfully!");
      return true;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false;
    });
};

export const activeLicense = (id) => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return licenseApi
    .activeLicense(id)
    .then(response => {
      dispatch(actions.licenseUpdated());
      toast.success("Activate License Successfully!");
      return true;
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
      return false;
    });
};


export const fetchTerminalInfo = terminaLStockId => dispatch => {
  dispatch(actions.startCall({ callType: callTypes.action }));
  return terminalApi
    .findTerminalInfo(terminaLStockId)
    .then(response => {
      dispatch(actions.terminalInfoFetched(response));
    })
    .catch(error => {
      dispatch(actions.catchError({ error, callType: callTypes.action }));
    });
};
