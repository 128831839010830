import React, {createContext, useContext, useState, useCallback} from "react";
import {isEqual, isFunction} from "lodash";
import {initialFilter} from "./BankCardsUIHelpers";

const BankCardsUIContext = createContext();

export function useBankCardsUIContext() {
  return useContext(BankCardsUIContext);
}

export const BankCardsUIConsumer = BankCardsUIContext.Consumer;

export function BankCardsUIProvider({uIEvents = {}, children}) {
  const [queryParams, setQueryParamsBase] = useState(initialFilter);
  const setQueryParams = useCallback(nextQueryParams => {
    setQueryParamsBase(prevQueryParams => {
      if (isFunction(nextQueryParams)) {
        nextQueryParams = nextQueryParams(prevQueryParams);
      }

      if (isEqual(prevQueryParams, nextQueryParams)) {
        return prevQueryParams;
      }

      return nextQueryParams;
    });
  }, []);

  const initFormBankCardsValues =  {
    bankCardNumber: "",
  }

  const value = {
    queryParams,
    setQueryParamsBase,
    setQueryParams,
    initFormBankCardsValues,
    openUpdateBankCardModal: uIEvents.openUpdateBankCardModal,
  };

  return <BankCardsUIContext.Provider value={value}>{children}</BankCardsUIContext.Provider>;
}
