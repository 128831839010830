import {all} from "redux-saga/effects";
import {combineReducers} from "redux";

import * as auth from "../app/modules/Auth/_redux/authRedux";
import {licensesSlice} from './licenses/licensesSlice';
import {bankCardsSlice} from './bank-cards/bankCardsSlice';
import {licenseReseller3rdSlice} from "../app/pages/Licenses/Reseller3rdSystem/store/licenses-reseller3rd/licensesReseller3rdSlice";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  licenses: licensesSlice.reducer,
  reseller3rdLicenses: licenseReseller3rdSlice.reducer,
  bankCards: bankCardsSlice.reducer
});

export function* rootSaga() {
  yield all([auth.saga()]);
}
