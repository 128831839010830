import {createSlice} from "@reduxjs/toolkit";

const initialCustomersState = {
  listLoading: false,
  totalCount: 0,
  entities: null,
  terminalInfo: null,
  generatedLicenses: null
};
export const callTypes = {
  list: "list",
  action: "action"
};

export const licensesSlice = createSlice({
  name: "licenses",
  initialState: initialCustomersState,
  reducers: {
    catchError: (state, action) => {
      state.error = `${action.type}: ${action.payload.error}`;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = false;
      } else {
        state.actionsLoading = false;
      }
    },
    startCall: (state, action) => {
      state.error = null;
      if (action.payload.callType === callTypes.list) {
        state.listLoading = true;
        state.entities = [];
        state.totalCount = 0;
      } else {
        state.actionsLoading = true;
      }
    },
    licensesFetched: (state, action) => {
      const { totalCount, entities } = action.payload;
      state.listLoading = false;
      state.error = null;
      state.entities = entities;
      state.totalCount = totalCount;
    },
    licenseUpdated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
    },
    licenseGenerated: (state, action) => {
      state.error = null;
      state.actionsLoading = false;
      state.generatedLicenses = action.payload;
    },
    terminalInfoFetched: (state, action) => {
      state.actionsLoading = false;
      state.error = null;
      state.terminalInfo = action.payload;
    },
    resetState: (state) => {
      state.listLoading = false;
      state.totalCount = false;
      state.entities = null;
      state.terminalInfo = null;
      state.generatedLicenses = null;
    }
  }
});
